<template>
  <AppWrapper>
    <div class="app__install">
      <div class="app__install--stats">
        <div
          class="app__install--stats__container"
          v-for="stat in stats"
          v-bind:key="stat.id"
        >
          <h5>{{ stat.value }}</h5>
          <p>{{ stat.type }}</p>
        </div>
      </div>

      <div class="app__install--contents">
        <div class="app__install--contents__title">
          <div class="app__install--contents__title--heading">
            <h4>Why install a <span>solar</span></h4>
            <h4>solution with <span>Powernow?</span></h4>

            <h3>Why install a <span>solar</span> solution</h3>
            <h3>with <span>Powernow?</span></h3>
          </div>

          <div class="app__install--contents__title--paragraph">
            <p class="app__install--contents__title--paragraph__mobile">Powernow is committed to supporting your</p>
            <p class="app__install--contents__title--paragraph__mobile">career, business and life, with our range of</p>
            <p class="app__install--contents__title--paragraph__mobile">instalment alternative energy plans.</p>

            <p class="app__install--contents__title--paragraph__large">Powernow is committed to supporting your career,</p>
            <p class="app__install--contents__title--paragraph__large">business and life, with our range of instalment</p>
            <p class="app__install--contents__title--paragraph__large">alternative energy plans.</p>
          </div>
        </div>

        <div class="app__install--contents__process">
          <template
            v-for="(process, i) in processes"
            v-bind:key="process.id"
          >
            <div :class="`app__install--contents__process--step`">
              <img :src="require(`@/assets/${process.icon}.svg`)" :alt="process.icon" />
              <h4>{{ process.title }}</h4>
              <div>
                <p
                  v-for="(text, idx) in process.body"
                  v-bind:key="idx"
                >
                  {{ text }}
                </p>
              </div>
            </div>

            <hr v-if="i !== (processes.length -1)" />

            <div :class="`app__install--contents__process--step-large step${process.id}`">
              <div class="app__install--contents__process--step-large__box">
                <img :src="require(`@/assets/${process.icon}.svg`)" :alt="process.icon" />
                <h4>{{ process.title }}</h4>
                <div>
                  <p
                    v-for="(text, idx) in process.large"
                    v-bind:key="idx"
                  >
                    {{ text }}
                  </p>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </AppWrapper>
</template>

<script setup>
import AppWrapper from '@/components/AppWrapper.vue';
import { computed, ref, onMounted } from 'vue';
import axios from 'axios';

const countData = ref({
  totalInstallations: 0,
  totalCustomers: 0,
  totalStates: 0,
  equiptmentsDelivered: 0,
});

onMounted(() => {
  getCountData();
})

const stats = computed(() => {
  const totalInstallations = countData.value.totalInstallations ? countData.value.totalInstallations * 1.5 : 0;
  const totalQuotes = countData.value.totalQuotes ? countData.value.totalQuotes : 0;
  const totalStates = countData.value.totalStates ? countData.value.totalStates : 0;
  const totalEquipmentDelivered = countData.value.equiptmentsDelivered ? countData.value.equiptmentsDelivered : 0;

  return [
    { value: `${Math.round(totalInstallations)}+`, type: "Total installations", id: 1 },
    { value: `${totalQuotes}+`, type: "Total Quotes", id: 2 },
    { value: `${totalStates}+`, type: "States served", id: 3 },
    { value: `${totalEquipmentDelivered}+`, type: "Equipments delivered", id: 4 },
  ]
});

const processes = computed(() => {
  return [
    {
      id: 1,
      icon: "power",
      title: "Enjoy uninterrupted power",
      body: [
        "Keep your plans going, even when traditional",
        "power supply is down. Never experience",
        "electricity downtimes again."
      ],
      large: [
        "Keep your plans going, even when traditional power",
        "supply is down. Never experience electricity downtimes",
        "again."
      ]
    },
    {
      id: 2,
      icon: "payment",
      title: "Simple and flexible payment plans",
      body: [
        "Enjoy a hassle-free application process, and",
        "choose from our flexible payment plans,",
        "tailored to your needs without breaking the",
        "bank."
      ],
      large: [
        "Enjoy a hassle-free application process, and choose",
        "from our flexible payment plans, tailored to your needs",
        "without breaking the bank.",
      ]
    },
    {
      id: 3,
      icon: "warranty",
      title: "Equipment warranty",
      body: [
        "We provide a comprehensive warranty on all",
        "our equipment, ensuring hassle-free usage",
        "and protection against unexpected issues."
      ],
      large: [
        "We provide a comprehensive warranty on all our",
        "equipment, ensuring hassle-free usage and protection",
        "against unexpected issues."
      ]
    },
    {
      id: 4,
      icon: "easy",
      title: "Easy resolution process",
      body: [
        "Your satisfaction matters to us. Experience a",
        "smooth and efficient resolution process where",
        "we promptly address any concerns you may",
        "have."
      ],
      large: [
        "Your satisfaction matters to us. Experience a smooth",
        "and efficient resolution process where we promptly",
        "address any concerns you may have.",
      ]
    }
  ]
})

const getCountData = () => {
  axios.get(
    `${process.env.VUE_APP_GREEN_ENERGY_API_URL}WebSite/StatisticsCount?key=usdsfv9hdvv9090d90u09sdvj09`,
  )
    .then((data) => {
      countData.value = data.data
    })
    .catch((e) => {
      console.log("err", e.response);
    });
}
</script>

<template>
  <div class="app__loader" :style="`width: ${props.size}%`">
    <div class="app__loader--skeleton" style="width: 100%" />
  </div>
</template>

<script setup>
/* eslint-disable  no-unused-vars */
/* eslint-disable  no-undef */

const props = defineProps({ size: String });
</script>

import "@/styles/style.scss";
import 'aos/dist/aos.css';

import AOS from 'aos';
import VueGtag from "vue-gtag";
import App from './App.vue'
import VueAxios from 'vue-axios';
import axios from 'axios';
import { createApp } from 'vue'
import router from './router'

AOS.init();

createApp(App)
  .use(VueAxios, axios)
  .use(VueGtag, {
    appName: 'Powernow Website',
    pageTrackerScreenviewEnabled: true,
    config: { 
      id: process.env.VUE_APP_GA_MEASUREMENT_ID,
    },
    router
  })
  .use(router)
  .mount('#app')

<template>
  <app-wrapper>
    <div class="app__testimony">
      <div class="app__testimony--words">
        <div class="app__testimony--words__header">
          <h4>Words of <span>Delight</span> from Our</h4>
          <h4>Customers</h4>
        </div>

        <div class="app__testimony--words__header_large">
          <h4>Words of <span>Delight</span></h4>
          <h4>from Our Customers</h4>
        </div>

        <div class="app__testimony--words__desc">
          <p>Make your first payment, and your inverter will</p>
          <p>be delivered, with an assigned technician to</p>
          <p>oversee its installation.</p>
        </div>

        <div class="app__testimony--words__desc_large">
          <p>Make your first payment, and your inverter will be</p>
          <p>delivered, with an assigned technician to oversee its</p>
          <p>installation.</p>
        </div>
      </div>

      <div class="app__testimony--people">
        <Carousel :itemsToShow="1.1" :autoplay="4000" :wrapAround="true" :transition="3000">
          <Slide v-for="testimony in testimonies" :key="testimony.id">
            <div class="app__testimony--people__content">
              <div class="app__testimony--people__content--box">
                <div class="app__testimony--people__content--box__title">
                  <img :src="require(`@/assets/person${testimony.id}.png`)" :alt="`person${testimony.id}`" />
                  <div class="app__testimony--people__content--box__title--text">
                    <h5>{{ testimony.title.name }}</h5>
                    <p>{{ testimony.title.position }}</p>
                  </div>
                </div>

                <div class="app__testimony--people__content--box__body">
                  <p>{{ testimony.body }}</p>
                </div>
              </div>
            </div>
          </Slide>
        </Carousel>
      </div>

      <div class="app__testimony--overflow">
        <div class="app__testimony--overflow--people_large">
          <Carousel :itemsToShow="2.3" :autoplay="6000" :wrapAround="true" :transition="3000">
            <Slide v-for="testimony in testimonies" :key="testimony.id">
              <div class="app__testimony--overflow--people_large__content">
                <div class="app__testimony--overflow--people_large__content--box">
                  <div class="app__testimony--overflow--people_large__content--box__title">
                    <img :src="require(`@/assets/person${testimony.id}.png`)" :alt="`person${testimony.id}`" />
                    <div class="app__testimony--overflow--people_large__content--box__title--text">
                      <h5>{{ testimony.title.name }}</h5>
                      <p>{{ testimony.title.position }}</p>
                    </div>
                  </div>

                  <div class="app__testimony--overflow--people_large__content--box__body">
                    <p>{{ testimony.body }}</p>
                  </div>
                </div>
              </div>
            </Slide>
          </Carousel>
        </div>
      </div>
    </div>
  </app-wrapper>
</template>

<script setup>
import AppWrapper from '@/components/AppWrapper.vue';
import { computed } from 'vue';
import { Carousel, Slide } from 'vue3-carousel'

const testimonies = computed(() => {
  return [
    {
      id: 1,
      title: {
        name: "Lisa",
        position: "Eco-conscious influencer, Abuja"
      },
      body: `Power Now is amazing for my business! Their inverters are like magic.
              They give us the power we need without costing too much. Now I can
              focus on my work and not worry about running out of power.
              Power Now is like having a superhero on our side`
    },
    {
      id: 2,
      title: {
        name: "Michael",
        position: "Eco-conscious influencer, Abuja"
      },
      body: `Power Now is amazing for my business! Their inverters are like magic.
              They give us the power we need without costing too much. Now I
              can focus on my work and not worry about running out of power.
              Power Now is like having a superhero on our side`
    },
    {
      id: 3,
      title: {
        name: "David",
        position: "Satisfied Customer, Lagos"
      },
      body: `Power Now is the best! Their solar panels and inverters have a warranty,
              so I know I'm covered if anything goes wrong. The customer support team
              was awesome and fixed a small issue I had right away. I recommend Power
              Now. They care about making us happy.`
    },
    {
      id: 4,
      title: {
        name: "John",
        position: "Barbing Salon Owner, Lagos"
      },
      body: `Power Now has been a lifesaver. With the recent increase in petrol price,
              it's been even harder to get electricity. But Power Now provides a
              solution. Their solar panels and inverters are reliable and affordable.
              I'm grateful to Power Now for helping us in this tough time`
    },
    {
      id: 5,
      title: {
        name: "Sarah",
        position: "Tech Entrepreneur, Ibadan"
      },
      body: `Choosing Power Now was a smart move. The process was simple,
              like a piece of cake. Now I have clean energy that doesn’t cost
              a lot. I love that they care about the environment too.
              Power Now has given me the power to be green. Thumbs up!`
    },
  ]
})
</script>

<style scoped>
.carousel__slide {
  height: 300px;
  padding: 5px;
}

.carousel__viewport {
  perspective: 2000px;
}

.carousel__track {
  transform-style: preserve-3d;
}

.carousel__slide--sliding {
  transition: 0.5s;
}

.carousel__slide {
  opacity: 0.9;
  transform: rotateY(-20deg) scale(0.9);
}

.carousel__slide--active ~ .carousel__slide {
  transform: rotateY(20deg) scale(0.9);
}

.carousel__slide--prev {
  opacity: 1;
  transform: rotateY(-10deg) scale(0.95);
}

.carousel__slide--next {
  opacity: 1;
  transform: rotateY(10deg) scale(0.95);
}

.carousel__slide--active {
  opacity: 1;
  transform: rotateY(0) scale(1.1);
}
</style>

<template>
  <app-wrapper>
    <div class="app__order">
      <div class="app__order--container">
        <div class="app__order--container__header">
          <div class="app__order--container__header--title">
            <h4 class="first">How to order our</h4>
            <h4 class="second">solutions<span>.</span></h4>
          </div>

          <div class="app__order--container__header--paragraph">
            <p>Follow these steps to access our solution, it's</p>
            <p>as simple as abc.</p>
          </div>
        </div>

        <div
          class="app__order--container__process"
          v-for="process in orderProcesses"
          v-bind:key="process.id"
        >
          <div class="app__order--container__process--icon">{{ process.id }}</div>

          <div class="app__order--container__process--info">
            <p v-for="(text, i) in process.description" v-bind:key="i">
              {{ text }}
            </p>
          </div>
        </div>

        <div
          class="app__order--container__process-large"
        >
          <div class="app__order--container__process-large--icons">
            <div class="app__order--container__process-large--icons__icon">1</div>
            <div class="app__order--container__process-large--icons__vr_short"></div>

            <div class="app__order--container__process-large--icons__icon">2</div>
            <div class="app__order--container__process-large--icons__vr"></div>

            <div class="app__order--container__process-large--icons__icon">3</div>
          </div>

          <div class="app__order--container__process-large--paragraph">
            <div class="app__order--container__process-large--paragraph__text">
              <p>Download our app and create</p>
              <p>an account, verify your mail</p>
              <p>and set a password</p>
            </div>

            <div class="app__order--container__process-large--paragraph__text">
              <p>Fill in your location and select</p>
              <p>a plan from our list of</p>
              <p>packages suited to meet your</p>
              <p>various needs</p>
            </div>

            <div class="app__order--container__process-large--paragraph__text">
              <p>Download our app and create</p>
              <p>an account, verify your mail</p>
              <p>and set a password</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </app-wrapper>
</template>

<script setup>
import AppWrapper from '@/components/AppWrapper.vue';
import { computed } from 'vue';

const orderProcesses = computed(() => {
  return [
    {
      id: 1,
      delay: 500,
      duration: 800,
      description: [
        "Download our app and create an",
        "account, verify your mail and set a",
        "password"
      ]
    },
    {
      id: 2,
      delay: 700,
      duration: 1000,
      description: [
        "Fill in your location and select a plan",
        "from our list of packages suited to meet",
        "your various needs"
      ]
    },
    {
      id: 3,
      delay: 900,
      duration: 1200,
      description: [
        "Download our app and create an",
        "account, verify your mail and set",
        "a password"
      ]
    }
  ]
})
</script>

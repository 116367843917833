import { createRouter, createWebHistory } from "vue-router";

import HomeView from "@/views/HomeView.vue";
import TermsConditionView from "@/views/terms-conditions/TermsConditionView.vue";

const routes = [
  {
    path: "/",
    name: "HomeView",
    component: HomeView,
    meta: {
      layout: "standard",
    }
  },
  {
    path: "/terms-conditions/:tab",
    name: "TermsConditionView",
    component:TermsConditionView,
    meta: {
      layout: "terms"
    },
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  //scroll to top of the page
  scrollBehavior(to, from, savedPosition) {
    return (
      savedPosition ||
      new Promise((resolve) => {
        setTimeout(() => resolve({ top: 0, behavior: "smooth" }), 300);
      })
    );
  },
});

export default router;

<template>
  <div class="container">
    <header class="container__header">
      <AppWrapper>
        <slot name="header" />
      </AppWrapper>
    </header>
    <main class="container__content">
      <slot name="content" />
    </main>
    <footer class="container__footer">
      <AppWrapper>
        <slot name="footer" />
      </AppWrapper>
    </footer>
  </div>
</template>

<script>
import AppWrapper from '@/components/AppWrapper.vue';

export default {
  components: {
    AppWrapper
  }
}
</script>
<template>
  <AppWrapper>
    <div class="app__layout">
      <div class="app__layout--header">
        <h4>Our Legal Information</h4>
      </div>

      <div class="app__layout--tabs">
        <input type="radio" id="tab1" name="tab-control" checked>
        <input type="radio" id="tab2" name="tab-control">
        <!-- <input type="radio" id="tab3" name="tab-control"> -->

        <div class="app__layout--tabs__select">
          <ul>
            <li title="terms">
              <label for="tab1" role="button">
                <p class="terms">TERMS OF USE</p>
              </label>
            </li>
            
            <li title="privacy">
              <label for="tab2" role="button">
                <p class="privacy">PRIVACY</p>
              </label>
            </li>

            <!-- <li title="loans">
              <label for="tab3" role="button">
                <p class="loans">LOANS OFFER</p>
              </label>
            </li> -->
          </ul>

          <div class="app__layout--tabs__select--slider">
            <div class="app__layout--tabs__select--slider__indicator"></div>
          </div>
        </div>

        <div class="app__layout--tabs__contents">
          <TermsView />
          <PrivacyView />
          <LoansView />
        </div>
      </div>
    </div>
  </AppWrapper>
</template>

<script setup>
import { onMounted } from 'vue';
import AppWrapper from '@/components/AppWrapper.vue';
import TermsView from './components/TermsView.vue';
import PrivacyView from './components/PrivacyView.vue';
import LoansView from './components/LoansView.vue';
import { useRoute } from "vue-router";
import { event } from 'vue-gtag'

const route = useRoute();
onMounted(() => {
  clickActiveTab();
})

const clickActiveTab = () => {
  const type = route.params.tab == "tab1" ? "TERMS OF USE" : "PRIVACY";
  event(
    'ToggleTermsAndPrivacy',
    {
      method: 'User read the terms of use and privacy policy.',
      view: type,
    }
  );
  document.getElementById(`${route.params.tab}`).click();
}
</script>

// import { ref } from "vue";

export function useFormatFiguresToCurrency(input) {
  const formattedCurrency = { value: "" };
  if (!input || input.length === 0) return { formattedCurrency };

  let inputSource = input;

  if (isFinite(Number(inputSource))) {
    inputSource = inputSource.toString();
  }

  // importantly, we wil strip out all the special characters keeping only the decimals '9,000,____00****0.008.999-----' will output 900000.008.999
  const inputWithDecimalPointsOnly = inputSource.replace(/[^0-9.]/g, "");

  // we will assign the value we got now, in case it is good enough already, if it is something like *900000 or 900000.008
  let inputReadyToBeFormattedToNumber = inputWithDecimalPointsOnly;

  // we need to convert the stripped input to an array incase there are multiple decimal places in it *990990.9.9*
  const splitByDecimals = inputReadyToBeFormattedToNumber.split(".");

  // if there are more than 2 values in this array, it means the user provided something like *9000.9.9*, so we will just keep the first decimal place
  // splitByDecimals = ['900000', '008', '999'];
  if (splitByDecimals.length > 2) {
    const [main, ...floatingPoint] = splitByDecimals; // ['900000', ['008', '999']]
    const floatingPointToDecimal = floatingPoint.join(""); // '008999'
    inputReadyToBeFormattedToNumber = `${main}.${floatingPointToDecimal}`;
  }

  // now, we have 900000.008999 that is ready to converted to Currency format
  formattedCurrency.value = Number(
    inputReadyToBeFormattedToNumber
  ).toLocaleString("en-US", {
    maximumFractionDigits: 3,
  });

  return { formattedCurrency };
}

export function useConvertFormattedFiguresToNumber(formattedFigures) {
  const numberValue = { value: 0 };

  const stringValueWithNoSpaces = formattedFigures.replace(/,/g, "");
  const numberToTwoDecimalPlaces = Number(stringValueWithNoSpaces).toFixed(2);

  numberValue.value = Number(numberToTwoDecimalPlaces);

  return { numberValue };
}

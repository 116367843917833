<template>
  <AppWrapper>
    <div class="app__faq">
      <div class="app__faq--header">
        <h4>Frequently asked Questions</h4>
      </div>

      <div class="app__faq--questions">
        <div class="app__faq--questions__contents" v-for="faq in faqs" v-bind:key="faq.id">
          <input type="checkbox" :id="`question${faq.id}`" :name="`question${faq.id}`"  class="app__faq--questions__contents--input">

          <label :for="`question${faq.id}`" class="app__faq--questions__contents--label">
            {{ faq.question }}
          </label>

          <div class="app__faq--questions__contents--answer check_answer">
            <p>{{ faq.answer }}</p>
          </div>

          <div class="app__faq--questions__contents--icon check_icon"></div>
        </div>
      </div>
    </div>
  </AppWrapper>
</template>

<script setup>
import AppWrapper from '@/components/AppWrapper.vue';
import { computed } from 'vue';

const faqs = computed(() => {
  return [
    {
      id: 1,
      question: "How do I apply for Power Now's services?",
      answer: "Applying is quick and easy! Just get started, and we'll guide you through the process."
    },
    {
      id: 2,
      question: "Can I choose a payment plan that suits my budget?",
      answer: `Absolutely! We understand that everyone's financial situation is unique.
              That's why we offer flexible payment plans tailored to your budget and needs.`
    },
    {
      id: 3,
      question: "What happens if there's an issue with the equipment?",
      answer: `Yes, you will be charged a small part of the total system cost 
              and you will be able to spread the rest across a 6 - 24 month period.`
    },
    {
      id: 4,
      question: "How long does it take to process my application?",
      answer: `We know your time is valuable. That's why our team works diligently to ensure a prompt
              response to your application within twenty hours. There are no unnecessary delays.`
    }
  ]
})
</script>
<template>
  <AppWrapper>
    <div class="app__hero">
      <div class="app__hero--contents">
        <div class="app__hero--contents__header">
          <div class="app__hero--contents__header--slider">
            <p>
              Keep your
            </p>
            <ul class="app__hero--contents__header--slider__list">
              <li>work</li>
              <li>home</li>
              <li>work</li>
              <li>home</li>
            </ul>
          </div>
          <!-- <p>Keep your <span class="typewriter"></span></p> -->
          <p>
            going&nbsp;<img src="@/assets/strike.svg" alt="strike" />&nbsp;when the power
          </p>
          <p>goes out.</p>
        </div>

        <div class="app__hero--contents__header-large">
          <div class="app__hero--contents__header-large--slider">
            <p>
              Keep your
            </p>
            <ul class="app__hero--contents__header-large--slider__list">
              <li>work</li>
              <li>home</li>
              <li>work</li>
              <li>home</li>
            </ul>
          </div>
          <!-- <p>Keep your <span class="typewriter"></span></p> -->
          <p>
            going&nbsp;<img src="@/assets/strike.svg" alt="strike" />&nbsp;when the
          </p>
          <p>power goes out.</p>
        </div>

        <div class="app__hero--contents__body">
          <div class="app__hero--contents__body--left">
            <img
              src="@/assets/female.svg"
              alt="female"
              class="app__hero--contents__body--left__female"
            />
            <img
              src="@/assets/male.svg"
              alt="male"
              class="app__hero--contents__body--left__male"
            />
          </div>

          <div class="app__hero--contents__body--right">
            <p>Powernow is committed to supporting your</p>
            <p>career, business and life, with our range of</p>
            <p>instalment alternative energy plans.</p>
          </div>

          <div class="app__hero--contents__body--right-large">
            <p>Powernow is committed to supporting your career,</p>
            <p>business and life, with our range of instalment</p>
            <p>alternative energy plans.</p>
          </div>
        </div>

        <div class="app__hero--contents__action">
          <button @click="goToApp">Apply Now</button>

          <router-link
            :to="{
               name: 'TermsConditionView',
               params: { tab: 'tab1' },
            }"
            class="app__hero--contents__action--link"
          >
            See terms of service
          </router-link>
        </div>
      </div>

      <div class="app__hero--video">
        <div class="app__hero--video__play" id="player">
          <component :is="script" id="youtube-iframe-js-api-script" src="https://www.youtube.com/iframe_api" />
          <iframe
            src="https://www.youtube.com/embed/-KwTPhumVzk?si=3lE4VpHyu7FCt4wF?rel=0&enablejsapi=1"
            frameborder="0"
            allowfullscreen
          ></iframe>

          <!-- <iframe src="https://www.youtube.com/embed/-KwTPhumVzk?si=JoOwtNoXyqYg9j3O?rel=0&controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; modestbranding; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> -->

          <img src="@/assets/play_button.svg" alt="play video" />
        </div>

        <hr />
      </div>
    </div>
  </AppWrapper>
</template>

<script setup>
import { ref, computed } from 'vue';
import AppWrapper from '@/components/AppWrapper.vue';
import { event } from 'vue-gtag'

const script = ref("script");
const url = computed(() => process.env.VUE_APP_GREEN_ENERGY_WEB_APP_URL);

const goToApp = () => {
  event('ApplyNowBtn', { method: 'Send User to the web app from the apply now button on hero section' });
  const anchor = document.createElement('a');
  
  anchor.href = `${url.value}#/login`;
  anchor.target = "_blank";
  anchor.click();
}
</script>

<template>
  <AppWrapper>
    <div class="app__reliable">
      <div class="app__reliable--contents">
        <div class="app__reliable--contents__box">
          <div class="app__reliable--contents__box--header">
            <h4>Reliable energy<br />solutions, for <span>everyone</span>.</h4>
          </div>

          <div class="app__reliable--contents__box--content">
            <div class="app__reliable--contents__box--content__bold_text">
              <p>From individuals to small businesses, we help everyone with access to clean energy.</p>
            </div>

            <div class="app__reliable--contents__box--content__text">
              <p>Switching to green, sustainable energy should be easy, and we make sure it is.</p>
            </div>
          </div>

          <div class="app__reliable--contents__box--btn">
            <button @click="goToApp">Find a suitable plan</button>
          </div>
        </div>
      </div>

      <div class="app__reliable--images">
        <Carousel :itemsToShow="2.2" :autoplay="2000" :wrapAround="true" :transition="500">
          <Slide v-for="image in mobileImgSizes" :key="image.id">
            <div
              data-img-url="image_slider4.png"
              class="app__reliable--images__dimensions"
              :style="`
                width: ${image.width};
                height: ${image.height};
                background-image: url(${require(`@/assets/slider/${image.img}.png`)})
              `"
            ></div>
          </Slide>
        </Carousel>
      </div>

      <div class="app__reliable--overflow">
        <div class="app__reliable--overflow--images_large">
          <Carousel :itemsToShow="4.5" :autoplay="2000" :wrapAround="true" :transition="500">
            <Slide v-for="image in mobileImgSizes" :key="image.id">
              <div
                data-img-url="image_slider4.png"
                class="app__reliable--overflow--images_large__dimensions_large"
                :style="`
                  width: ${image.large.width};
                  height: ${image.large.height};
                  background-image: url(${require(`@/assets/slider/${image.large.img}.png`)})
                `"
              ></div>
            </Slide>
          </Carousel>
        </div>
      </div>
    </div>
  </AppWrapper>
</template>

<script setup>
import 'vue3-carousel/dist/carousel.css'
import AppWrapper from '@/components/AppWrapper.vue';
import { computed } from 'vue';
import { Carousel, Slide } from 'vue3-carousel'
import { event } from 'vue-gtag'

const mobileImgSizes = computed(() => {
  return [
    {
      id: 1,
      width: "181.9px",
      height: "147.49px",
      img: "image1",
      large: {
        width: "350px",
        height: "283.78px",
        img: "image1",
      }
    },
    {
      id: 2,
      width: "135.34px",
      height: "181.9px",
      img: "image2",
      large: {
        width: "260.42px",
        height: "350px",
        img: "image2",
      }
    },
    {
      id: 3,
      width: "181.9px",
      height: "121.27px",
      img: "image3",
      large: {
        width: "350px",
        height: "233.33px",
        img: "image3",
      }
    },
    {
      id: 4,
      width: "188px",
      height: "172px",
      img: "image4",
      large: {
        width: "362px",
        height: "331px",
        img: "image4",
      }
    },
    {
      id: 5,
      width: "145.7px",
      height: "181.9px",
      img: "image5",
      large: {
        width: "280.4px",
        height: "350px",
        img: "image5",
      }
    }
  ]
})
const url = computed(() => process.env.VUE_APP_GREEN_ENERGY_WEB_APP_URL);

const goToApp = () => {
  event('FindASuitablePlanBtn', { method: 'Send User to the web app to login and select a suitable plan' });
  const anchor = document.createElement('a');
  anchor.href = `${url.value}#/login`;
  anchor.target = "_blank";
  anchor.click();
}
</script>

<style scoped>
.carousel__slide {
  padding: 5px;
}

.carousel__slide--active {
  transform: rotateY(0) scale(0.9) !important;
}

.carousel__viewport {
  perspective: 2000px;
}

.carousel__track {
  transform-style:flat;
}

.carousel__slide--sliding {
  transition: 0.5s;
}

.carousel__slide {
  opacity: 0.9;
  transform: rotateY(-20deg) scale(0.9);
}

.carousel__slide--active ~ .carousel__slide {
  transform: rotateY(20deg) scale(0.9);
}

.carousel__slide--prev {
  opacity: 1;
  transform: rotateY(-10deg) scale(0.95);
}

.carousel__slide--next {
  opacity: 1;
  transform: rotateY(10deg) scale(0.95);
}

.carousel__slide--active {
  opacity: 1;
  transform: rotateY(0) scale(1.1);
}

@media screen and (min-width:1025px) { 
    /* big landscape tablets, laptops, and desktops */
    .carousel__track {
      height: 420px !important;
    }
}
</style>

<template>
  <AppWrapper>
    <div class="app__started">
      <div class="app__started--contents">
        <div class="app__started--contents__header">
          <h4>Get started right now</h4>
        </div>

        <div class="app__started--contents__text">
          <p>Alternative power solutions in the palm of</p>
          <p>your hands</p>
        </div>

        <div class="app__started--contents__text_large">
          <p>Alternative power solutions in the palm of your hands</p>
        </div>

        <div class="app__started--contents__list">
          <ul class="app__started--contents__list--content">
            <li><p>Get direct access to engineers post-installation</p></li>
            <li><p>Monitor repayments when they're due</p></li>
            <li><p>Easily report issues and make complaints</p></li>
          </ul>
        </div>

        <div class="app__started--contents__btn">
          <button @click="goToApp">Sign up now</button>
        </div>
      </div>

      <div class="app__started--image">
        <div class="app__started--image__box">
          <img
            class="app__started--image__box--img1"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-delay="500"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
            data-aos-mirror="true"
            data-aos-once="false"
            data-aos-anchor-placement="top-bottom"
            src="@/assets/phone_half.png"
            alt="phone-half"
          />
          <img 
            class="app__started--image__box--img2"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-delay="800"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
            data-aos-mirror="true"
            data-aos-once="false"
            data-aos-anchor-placement="top-bottom"
            src="@/assets/phone_full.png"
            alt="phone-full"
          />
        </div>
      </div>
    </div>
  </AppWrapper>
</template>

<script setup>
import AppWrapper from '@/components/AppWrapper.vue';
import { computed } from 'vue'
import { event } from 'vue-gtag'

const url = computed(() => process.env.VUE_APP_GREEN_ENERGY_WEB_APP_URL);

const goToApp = () => {
  event('SignUpBtn', { method: 'Send User to web app to create account' });
  const anchor = document.createElement('a');
  anchor.href = url.value;
  anchor.target = "_blank";
  anchor.click();
}
</script>